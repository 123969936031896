<template>
  <app-page hideBottomBar>
    <template v-slot:app-bar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{ $vuetify.lang.t("$vuetify.step_2_of_2") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/" class="decoration-none">{{
        $vuetify.lang.t("$vuetify.skip")
      }}</router-link>
    </template>

    <div class="auth-container">
      <div>
        <h2>{{$vuetify.lang.t('$vuetify.preferences')}}</h2>
        <p class="subtitle-1">
          {{ $vuetify.lang.t("$vuetify.house") }}
        </p>

        <p class="title-1">
          {{ $vuetify.lang.t("$vuetify.do_you_have_a_garden_in_your_house") }}
        </p>

        <div class="garden">
          <v-select
            v-model="garden_size"
            outlined
            hide-details
            item-text="text"
            item-value="value"
            :items="gardenSizeList"
            :label="$vuetify.lang.t('$vuetify.garden_size')"
          ></v-select>
        </div>

        <p class="title-1">
          {{ $vuetify.lang.t("$vuetify.how_many_animals_you_have_in_house") }}
        </p>
        <counter
          v-model="otherAnimals"
          class="kid-counter"
          @addition="updateAnimalCounter(true)"
          @subtraction="updateAnimalCounter(false)"
        />

        <!-- otherAnimalsTypes -->
        <div class="other-animals mt-5">
          <div
            class="other-animals-item mb-3"
            v-for="(animal, index) in otherAnimalsTypes"
            :key="index"
          >
            <v-select
              :items="animalsList"
              item-value="value"
              item-text="label"
              @input="(val) => animalChange(val, index)"
              :label="`${$vuetify.lang.t(`$vuetify.select_animal_type`)} ${
                index + 1
              }`"
              outlined
              hide-details
            ></v-select>
          </div>
        </div>
      </div>

      <div class="mb-10 d-flex align-items-center justify-content-between">
        <app-button
          color="grey-light"
          :loading="loading"
          :block="false"
          @click="$router.back()"
          >{{ $vuetify.lang.t(`$vuetify.back`) }}</app-button
        >

        <app-button :block="false" :loading="loading" @click="nextStep">{{
          $vuetify.lang.t(`$vuetify.next`)
        }}</app-button>
      </div>
    </div>
  </app-page>
</template>

<script>
import counter from "@/components/counter/counter";
import { GARDEN_SIZES } from "@/constants";
import { createNamespacedHelpers } from "vuex";
import snakeCase from "lodash/snakeCase";
const { mapMutations, mapGetters, mapActions, mapState } =
  createNamespacedHelpers("user");
export default {
  name: "preference-1",
  components: { counter },
  data() {
    return {};
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters(["GET_USER_PREFERENCE", "GET_USER_KEY"]),
    gardenSizeList() {
      return GARDEN_SIZES.map((s) => {
        return {
          ...s,
          text: this.$vuetify.lang.t(`$vuetify.${snakeCase(s.text)}`),
        };
      });
    },
    otherAnimals: {
      get() {
        return this.GET_USER_PREFERENCE("otherAnimals");
      },
      set(value) {
        this.SET_USER_PREFERENCE({ key: "otherAnimals", value });
      },
    },
    garden_size: {
      get() {
        return this.GET_USER_PREFERENCE("garden_size") || "no";
      },
      set(value) {
        this.SET_USER_PREFERENCE({ key: "garden_size", value });
      },
    },
    otherAnimalsTypes() {
      return this.GET_USER_PREFERENCE("otherAnimalsTypes");
    },
    animalsList() {
      return [
        { label: this.$vuetify.lang.t("$vuetify.dogs"), value: "Dogs" },
        { label: this.$vuetify.lang.t("$vuetify.cats"), value: "Cats" },
        {
          label: this.$vuetify.lang.t("$vuetify.other_animal"),
          value: "Other Animal",
        },
      ];
    },
  },
  methods: {
    ...mapMutations(["SET_USER_PREFERENCE"]),
    ...mapActions(["UPDATE_USER_PROFILE"]),
    updateAnimalCounter(isAddition) {
      if (isAddition) {
        const otherAnimals = [...this.GET_USER_PREFERENCE("otherAnimalsTypes")];
        otherAnimals.push("");
        this.SET_USER_PREFERENCE({
          key: "otherAnimalsTypes",
          value: otherAnimals,
        });
      } else {
        const otherAnimals = [...this.GET_USER_PREFERENCE("otherAnimalsTypes")];
        otherAnimals.splice(otherAnimals.length - 1, 1);
        this.SET_USER_PREFERENCE({
          key: "otherAnimalsTypes",
          value: otherAnimals,
        });
      }
    },
    animalChange(val, index) {
      const animalTypes = [...this.GET_USER_PREFERENCE("otherAnimalsTypes")];
      animalTypes[index] = val;
      this.SET_USER_PREFERENCE({
        key: "otherAnimalsTypes",
        value: [...animalTypes],
      });
    },
    async nextStep() {
      await this.UPDATE_USER_PROFILE({
        meta_data: this.GET_USER_KEY("preference"),
      });
      this.$router.push("/profile");
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  min-height: calc(100vh - 56px - 24px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;

  @media only screen and (min-width: 400px) {
    padding-bottom: 3rem;
  }
}

p.title-1 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.kid-counter {
  display: inline-block;
  padding: 0.3rem 0;
  border: 1px solid #333;
  border-radius: 12px;
}

.garden {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &--item {
    border: 1px solid #0000007d;
    border-radius: 12px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    padding: 1rem 1.2rem;
    text-align: center;
    transition: all 0.3s ease;

    &.active {
      color: #fff;
      background: #0dbff2;
      border-color: #0dbff2;
    }
  }
}

.garden-length {
  display: flex;
  align-items: center;

  &-input {
    input {
      border: 1px solid #0000007d;
      border-radius: 12px;
      padding: 0.7rem 0.5rem;
      display: inline-block;
      max-width: 80px;
      outline: none;
      box-shadow: none;
      text-align: center;
    }

    span {
      font-size: 12px;
      line-height: 22px;
      color: #000000;
      opacity: 0.5;
      display: block;
      text-align: center;
    }
  }

  .cross {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    opacity: 0.5;
    margin: 0 1rem;
    position: relative;
    top: -10px;
  }
}
</style>
