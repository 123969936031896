<template>
  <div class="counter">
    <v-btn
      icon
      class="counter--action"
      :disabled="counter == min"
      @click="updateCounter(false)"
    >
      <v-icon small>mdi-minus</v-icon>
    </v-btn>

    <span class="counter--value">{{ counter }}</span>

    <v-btn
      icon
      class="counter--action"
      :disabled="counter == max"
      @click="updateCounter(true)"
    >
      <v-icon small>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "counter",
  props: {
    value: Number,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      counter: this.value,
    };
  },
  methods: {
    updateCounter(addition) {
      if (addition) {
        this.counter++;
        this.$emit("addition");
      } else {
        this.counter--;
        this.$emit("subtraction");
      }

      this.$emit("input", this.counter);
    },
  },
};
</script>

<style lang="scss" scoped></style>
